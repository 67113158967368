<template>
  <div class="groupBuyList">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>拼团列表</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="search">
      <span>活动名称:</span>
      <el-input
        v-model="list.groupBuyName"
        placeholder="请输入活动名称"
      ></el-input>
      <span>活动状态:</span>
      <el-select v-model="list.status" placeholder="请选择">
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <span>是否显示:</span>

      <el-select clearable v-model="list.isShow" placeholder="请选择">
        <el-option
          v-for="item in  isShowOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <br/>
      <br/>
      <span>万旅网显示:</span>

      <el-select clearable v-model="list.wlShow" placeholder="请选择">
        <el-option
          v-for="item in optionsShow"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <el-button type="primary" @click="getgroupBuyList()">查找</el-button>
      <el-button
        type="primary"
        @click="$router.push('/addGroupBuy/0/0/0')"
        v-if="$store.state.powerList.indexOf('groupBuy:info:list:add') !== -1"
        >添加</el-button
      >
    </div>
    <el-table :data="tableData" border style="width: 100%" @selection-change="handleSelectionChange">
      <el-table-column align="center" type="selection"></el-table-column>
      <el-table-column align="center" prop="detp" label="排序">
        <template slot-scope="{ row }">
          <el-input
            class="input-new-tag"
            v-if="row.inputVisible"
            v-model="row.dept"
            ref="saveTagInput"
            size="small"
            @input="onInput"
            @keyup.enter.native="handleInputConfirm(row)"
            @blur="handleInputConfirm(row)"
          >
          </el-input>
          <el-button
            v-else
            class="button-new-tag"
            size="small"
            @click="onChangeSort(row)"
            >{{ row.dept }}</el-button
          >
        </template>
      </el-table-column>
      <el-table-column
        width="100"
        align="center"
        prop="groupBuyId"
        label="活动ID"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="groupBuyName"
        label="活动名称"
        width="300"
      ></el-table-column>
      <el-table-column
        width="300"
        align="center"
        prop="groupBuyTime"
        label="活动时间"
      ></el-table-column>
      <el-table-column align="center" label="活动图片">
        <template slot-scope="{ row }">
          <el-image
            id="img"
            :src="row.imgUrl"
            :preview-src-list="[row.imgUrl]"
          ></el-image>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        width="100"
        prop="groupBuyPeopleCount"
        label="成团人数"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="label"
        label="标签"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="stock"
        label="活动数量"
        width="100"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="strChange"
        label="是否改期"
        width="100"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="strStatus"
        label="活动状态"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="cardType"
        label="限定身份证"
        width="width"
      ></el-table-column>
      <el-table-column align="center" prop="isShow" label="是否显示">
      </el-table-column>
      <el-table-column align="center" prop="wlShow" label="万旅网显示">
      </el-table-column>
      <el-table-column label="操作" width="350">
        <template slot-scope="{ row }">
          <el-button
            size="mini"
            type="primary"
            v-if="
              (row.status == 2 || row.status == 1 || row.status == 3) &&
              $store.state.powerList.indexOf('groupBuy:info:list:update') !== -1
            "
            @click="onInvalidRow(row.groupBuyId)"
            >使失效</el-button
          >

          <el-button
            size="mini"
            type="primary"
            @click="onIsUpgrade(row.groupBuyId)"
            v-if="
              $store.state.powerList.indexOf('groupBuy:upgrade:list:select') !==
              -1 && row.type != 5
            "
            >升级设置</el-button
          >

          <el-button
            size="mini"
            style="margin: 5px"
            type="primary"
            @click="$router.push(`/addGroupBuy/${row.groupBuyId}/0/0`)"
            v-if="
              $store.state.powerList.indexOf('groupBuy:info:list:edit') !== -1
            "
            >编辑</el-button
          >
          
          <el-button
            size="mini"
            type="primary"
            @click="onDelRow(row.groupBuyId)"
            v-if="
              $store.state.powerList.indexOf('groupBuy:info:list:delete') !== -1
            "
            >删除</el-button
          >
          <el-button
            size="mini"
            type="primary"
            @click="onShowAddress(row.groupBuyId)"
            >查看页面地址</el-button
          >
          <el-button
            size="mini"
            type="primary"
            @click="erCodeOpen(row.groupBuyId)"
            >生成小程序码</el-button
          >
          <el-button
            size="mini"
            style="margin: 5px"
            type="primary"
            @click="$router.push(`/groupLog?id=${row.groupBuyId}`)"
            v-if="
              $store.state.powerList.indexOf('group:buy:list:log') !== -1
            "
            >查看日志</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="btn">
      <el-button
        type="primary"
        @click="onChangeAllStatusShow(1)"
        v-if="$store.state.powerList.indexOf('groupBuy:info:list:wlShow') !== -1"
        >万旅网批量显示</el-button
      >
      <el-button
        type="primary"
        @click="onChangeAllStatusShow(0)"
        v-if="$store.state.powerList.indexOf('groupBuy:info:list:wlShow') !== -1"
        >万旅网批量隐藏</el-button
      >
    </div>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="list.currentPage"
      :page-sizes="[5, 10, 15, 20]"
      :page-size="list.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pagination.total"
    >
    </el-pagination>
    <el-dialog title="查看页面详情地址" :visible.sync="showSrc" width="650px">
      <div id="address">
        前台详情地址:<el-input
          style="width: 400px"
          v-model="address"
          readonly
          id="wrapper"
        ></el-input>
        <el-button type="primary" icon="el-icon-document-copy" @click="urla"
          >一键复制</el-button
        >
      </div>
      <div slot="footer">
        <el-button @click="showSrc = false">取 消</el-button>
        <el-button type="primary" @click="showSrc = false">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="提示" :visible.sync="codeVisible" width="30%">
      <div class="inner_top">
        <el-input
          style="width: 300px"
          v-model.trim="QRcodeSize"
          placeholder="请输入生成的二维码大小"
        ></el-input>

        <el-button type="primary" @click="generateQRcode">生成</el-button>
      </div>
      <div style="color: red">推荐宽度：280px-1280px</div>
      <el-image v-show="imgUrl" fit="fill" :src="imgUrl"></el-image>
      <span slot="footer" class="dialog-footer">
        <span>右键点击图片，点击另存为保存图片</span>
        <el-button @click="codeVisible = false">取 消</el-button>
        <el-button type="primary" @click="codeVisible = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  groupBuyList,
  delGroupBuy,
  groupBuyInvalid,
  selectIsUpgrade,
} from "../../../api/groupBuy";
import { changeSort,wanlvGroupShow } from "../../../api/groupBuy";
import { createEr6 } from "../../../api/erCode";

export default {
  name: "groupBuyList",
  data() {
    return {
      QRcodeSize: "",
      codeVisible: false,
      groupId: "",
      imgUrl: "",
      tableData: [],
      pagination: {},
      address: "",
      showSrc: false,
      options: [
        { value: 0, label: "全部" },
        { value: 1, label: "进行中" },
        { value: 2, label: "未开始" },
        { value: 3, label: "已结束" },
        { value: 4, label: "已失效" },
      ],
      list: {
        currentPage: 1,
        pageSize: 5,
        groupBuyName: "",
        status: null,
        isShow: -2,
        wlShow:-1
      },
      optionsShow: [
        {
          value: -1,
          label: "全部",
        },
        {
          value: 1,
          label: "显示",
        },
        {
          value: 0,
          label: "隐藏",
        },
      ],
      isShowOptions: [
        {
          value: -2,
          label: "全部",
        },
        {
          value: 1,
          label: "显示",
        },
        {
          value: 0,
          label: "隐藏",
        },
      ],
    };
  },
  created() {
    this.getgroupBuyList();
  },
  methods: {
    handleSelectionChange(val) {
      this.ids = val.map((item) => item.groupBuyId).join(",");
    
    },
    onChangeAllStatusShow(status){
      if(!this.ids){
        return this.$message({
          type: "warning",
          message: "请选择要修改的内容!",
        });
      }
      // 是否修改
      this.$confirm("是否要批量修改选中拼团的状态?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          console.log(this.ids)
          const { data } = await wanlvGroupShow({
            groupBuyId: this.ids,
            wlShow:status,
          });
          if (data.code === 0) {
            this.getgroupBuyList();
            this.$message({
              type: "success",
              message: data.msg,
            });
          } else {
            this.$message({
              type: "error",
              message: data.msg,
            });
          }
        })
        .catch(() => {
          
        });

    },
    erCodeOpen(id) {
      this.codeVisible = true;
      this.groupId = id;
      this.imgUrl = "";
    },
    async generateQRcode() {
      this.imgUrl = "";
      if (!this.QRcodeSize) {
        this.$message({ message: "请输入宽度", type: "warning" });
      } else {
        const { data } = await createEr6({
          groupBuyId: this.groupId,
          widthSize: this.QRcodeSize,
        });
        this.imgUrl = data.msg;
        console.log(this.imgUrl);
      }
    },
    async onGOSecKill(roomId) {
      const { data } = await isActivityKill({
        prodType: 1,
        prodId: roomId,
      });
      if (data.code == 0) {
        this.$message.success(data.msg);
        this.$router.push(`/addSeckill/0/${roomId}/${1}`);
      } else {
        this.$message.error(data.msg);
      }
    },
    onSetPrice() {
      this.$router.push(`/groupBuyPrice/${id}`);
    },
    onShowAddress(id) {
      this.address =
        "groupBuy/pages/groupBuyDetails/groupBuyDetails?groupBuyId=" + id;
      this.showSrc = true;
    },
    urla() {
      var inputElement = document.getElementById("wrapper");
      //选中input框的内容
      inputElement.select();
      // 执行浏览器复制命令
      document.execCommand("Copy");
      this.$message({ message: "复制成功", type: "success" }); //此处为结合使用element-ui的Message 消息提示组件
    },
    async onIsUpgrade(groupBuyId) {
      const { data } = await selectIsUpgrade({
        groupBuyId,
      });
      if (data.code == 0) {
        this.$message.success(data.msg);
        this.$router.push(`/upgradeList/${groupBuyId}`);
      } else {
        this.$message.error(data.msg);
      }
    },
    async getgroupBuyList() {
      const { data } = await groupBuyList(this.list);
      data.list.map((item) => {
        item.inputVisible = false;
      });
      this.tableData = data.list;
      this.pagination = data.pagination;
    },
    onInput(value) {
      var reg = /^[0-9]*[1-9][0-9]*$/;
      var reg2 = /^-[0-9]*[1-9][0-9]*$/;
      if (!reg.test(value) && !reg2.test(value)) {
        return this.$message({
          type: "warning",
          message: "请输入整数!",
        });
      }
    },
    onChangeSort(row) {
      row.inputVisible = true;
    },
    async handleInputConfirm(row) {
      const { data } = await changeSort({
        groupBuyId: row.groupBuyId,
        dept: row.dept,
      });
      row.inputVisible = false;
      this.getgroupBuyList();
    },
    onInvalidRow(groupBuyId) {
      this.$confirm("是否要让这条拼团失效?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const { data } = await groupBuyInvalid({
            groupBuyId,
          });
          if (data.code == 0) {
            this.$message({
              type: "success",
              message: data.msg,
            });
            this.getgroupBuyList();
          } else {
            this.$message({
              type: "error",
              message: data.msg,
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    onDelRow(groupBuyId) {
      this.$confirm("是否要删除这条数据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const { data } = await delGroupBuy({
            groupBuyId,
          });
          if (data.code == 0) {
            this.$message({
              type: "success",
              message: data.msg,
            });
            this.getgroupBuyList();
          } else {
            this.$message({
              type: "error",
              message: data.msg,
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    handleSizeChange(num) {
      this.list.pageSize = num;
      this.getgroupBuyList();
    },
    handleCurrentChange(num) {
      this.list.currentPage = num;
      this.getgroupBuyList();
    },
  },
};
</script>
<style lang="less" scoped>
.btn {
    float: left;
    margin-top: 50px;
  }
.groupBuyList {
  .el-breadcrumb {
    height: 35px;
    border-bottom: 1px solid rgb(192, 191, 191);
  }
  .search {
    margin-top: 50px;
    span {
      margin: 0 20px 0 40px;
    }
    .el-input {
      width: 400px;
    }
    .el-button {
      margin-left: 20px;
    }
  }
  .el-table {
    margin-top: 50px;
    #img {
      width: 108px;
      height: 61px;
    }
  }
  .btn {
    margin-top: 50px;
  }
  .el-pagination {
    margin-top: 50px;
    float: right;
  }
}
</style>